span.iXp-tooltip
  background: #2d2d2db3;
  color: #fff;
  padding: 3px 5px;
  border-radius: 3px
  position: absolute;
  z-index: 3;
  font-size: 8pt;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  transition: all 1s ease-out;
  margin-left: 30px;
  top: 0;
  &:hover
   opacity 1 !important 
  &.untouchable
    display none
@media (max-width: 480px) {
  span.iXp-tooltip {
    bottom: -2.5em;
    left: 0;
    right: 0;
    top: initial;
    margin-left: 0px;
  }
}
.has-tooltip
  .tooltip-msg
    display             none

.has-tooltip
  span.iXp-tooltip.shown
    animation-name      tooltp-shown
    animation-fill-mode forwards
    animation-duration  1s
  span.iXp-tooltip.un-showing
    animation-name      tooltp-un-showing
    animation-fill-mode forwards
    animation-duration  0.5s

@keyframes tooltp-shown
  0%
    opacity             0
  
  100%
    opacity             1

@keyframes tooltp-un-showing
  0%
    opacity             1
  
  100%
    opacity             0