
/* line 55 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */

/* line 1 : C:\gloups\node_modules\stylus\lib\functions\index.styl */

/* line 1 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
span.iXp-tooltip {
  background: rgba(45,45,45,0.702);
  color: #fff;
  padding: 3px 5px;
  border-radius: 3px;
  position: absolute;
  z-index: 3;
  font-size: 8pt;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  transition: all 1s ease-out;
  margin-left: 30px;
  top: 0;
}

/* line 16 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
span.iXp-tooltip:hover {
  opacity: 1 !important;
}

/* line 18 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
span.iXp-tooltip.untouchable {
  display: none;
}

/* line 20 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
@media (max-width: 480px) {

/* line 21 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  span.iXp-tooltip {
    bottom: -2.5em;
    left: 0;
    right: 0;
    top: initial;
    margin-left: 0px;
  }
}

/* line 29 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */

/* line 30 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
.has-tooltip .tooltip-msg {
  display: none;
}

/* line 33 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */

/* line 34 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
.has-tooltip span.iXp-tooltip.shown {
  animation-name: tooltp-shown;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

/* line 38 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
.has-tooltip span.iXp-tooltip.un-showing {
  animation-name: tooltp-un-showing;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
}

/* line 43 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
@-moz-keyframes tooltp-shown {

/* line 44 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  0% {
    opacity: 0;
  }

/* line 47 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  100% {
    opacity: 1;
  }
}

/* line 43 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
@-webkit-keyframes tooltp-shown {

/* line 44 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  0% {
    opacity: 0;
  }

/* line 47 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  100% {
    opacity: 1;
  }
}

/* line 43 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
@-o-keyframes tooltp-shown {

/* line 44 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  0% {
    opacity: 0;
  }

/* line 47 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  100% {
    opacity: 1;
  }
}

/* line 43 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
@keyframes tooltp-shown {

/* line 44 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  0% {
    opacity: 0;
  }

/* line 47 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  100% {
    opacity: 1;
  }
}

/* line 50 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
@-moz-keyframes tooltp-un-showing {

/* line 51 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  0% {
    opacity: 1;
  }

/* line 54 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  100% {
    opacity: 0;
  }
}

/* line 50 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
@-webkit-keyframes tooltp-un-showing {

/* line 51 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  0% {
    opacity: 1;
  }

/* line 54 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  100% {
    opacity: 0;
  }
}

/* line 50 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
@-o-keyframes tooltp-un-showing {

/* line 51 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  0% {
    opacity: 1;
  }

/* line 54 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  100% {
    opacity: 0;
  }
}

/* line 50 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
@keyframes tooltp-un-showing {

/* line 51 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  0% {
    opacity: 1;
  }

/* line 54 : C:\xampp\htdocs\tickets4biz\public\assets\css\animations\ixp-toolkit.styl */
  100% {
    opacity: 0;
  }
}
